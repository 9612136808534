import {mapActions, mapGetters} from "vuex";

export default {
  name: "nav-bar",
  computed:{
    ...mapGetters({
      user: 'profile/user',
      isProfessional:'profile/isProfessional'
    })
  },
  methods:{
    ...mapActions({
      logout:'auth/LOGOUT'
    })
  }
}